<template>
  <div v-if="loading">
    <Loader />
  </div>
  <div class="card" v-if="!loading">
    <div class="card-header">
      <h3 class="card-title" style="font-size: 20px; font-weight: bold">
        import customer
      </h3>
      <div class="card-toolbar">
        <button
          type="button"
          class="btn btn-sm btn-light btn-success me-2"
          style="font-size: 14px; font-weight: bold"
          @click="toggleAttachFile()"
        >
          <input
            v-show="false"
            type="file"
            id="inputexcel"
            accept=".xlsx"
            @change="onFileChange($event)"
          />
          + Import Excel
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2/dist/sweetalert2.js";
import customerApi from "@/api/customer/";
import whApi from "@/api/warehouse/";
import userApi from "@/api/user/";
import readXlsxFile from "read-excel-file";

import DialogConfirmByPass from "../../components/dialogConfirmByPass.vue";
import Pagination from "../../components/pagination.vue";
import Loader from "../../components/loaderIndex.vue";
import Search from "../../components/search.vue";
import { Decode, Encode } from "@/services";

export default {
  setup() {
    document.title = "J&N | เพิ่มประเภทสินค้า";
  },
  components: {
    DialogConfirmByPass,
    Pagination,
    Search,
    Loader,
  },
  data: () => ({
    loading: false,
    loadingUpdate: false,
    dataItemsShow: [],
    showing1: null,

    checkAll: false,
    dialogConfirmByPass: false,

    tableItems: [],
    itemApprove: {},

    searchInput: "",
    allData: [],
    columns: [
      { title: "no", dataIndex: "no" },
      { title: "logo_img", dataIndex: "logo_img" },
      { title: "company_code", dataIndex: "company_code" },
      { title: "company_name", dataIndex: "company_name" },
      { title: "", dataIndex: "action" },
    ],
    user: [],
    userAuth: [],
    userLocal: [],
    masterMenuId: 28,
  }),

  async created() {
    this.user = JSON.parse(Decode.decode(localStorage.getItem("user_temp")));
    console.log("this.user", this.user);
    // await this.getAll();
    this.checkMenu();
  },

  methods: {
    toggleAttachFile() {
      document.getElementById("inputexcel").click();
    },
    async onFileChange(event) {
      // this.notFoundProduct = [];
      // let responseData = [];
      // let responseUnit = [];
      let xlsxfile = event.target.files ? event.target.files[0] : null;
      // console.log("xlsxfile", xlsxfile);
      readXlsxFile(xlsxfile).then(async (rows) => {
        //   // console.log("rows:", rows);
        let index = 0;
        let routeId = 648;
        for (const excelData of rows) {
          if (routeId) {
            // let data = {
            //   search: excelData[0],
            //   companyId: localStorage.getItem("companyId"),
            // }
            let query = `?code=${excelData[0]}`;
            const responseSearch = await customerApi.customer.filterdata(query);
            console.log("responseSearch", responseSearch);
            // if (responseSearch.data.length > 0) {
            //   let id = responseSearch.data[0].id;
            //   let data = {
            //     code: responseSearch.data[0].code,
            //     companyId:responseSearch.data[0].companyId,
            //     routeId: 648,
            //   };
            //   const responseUpdate = await customerApi.customer.update(
            //     id,
            //     data
            //   );
            //   if (responseUpdate.response_status == "SUCCESS") {
            //     console.log("SUCCESS");
            //   }
            // } else {
            //   const responseget = await customerApi.customer.importM3(
            //     excelData[0]
            //   );
            //   if (responseget.response_status == "SUCCESS") {
            //     console.log("IMPORT SUCCESS");
                
            //   }
            // }
          } else {
            Swal.fire({
              icon: "error",
              title: "Van Code Invalid!",
              showConfirmButton: true,
            });
          }
          index++;
        }
      });
    },
    async checkMenu() {
      this.userLocal = JSON.parse(
        Decode.decode(localStorage.getItem("user_temp"))
      );

      console.log("this.userLocal", this.userLocal);
      let responseData = [];
      try {
        responseData = await userApi.user.checkAuth(
          this.userLocal.userGroupId,
          this.masterMenuId
        );
      } catch (error) {
        console.log(error);
      }
      if (responseData.response_status === "SUCCESS") {
        this.userAuth = responseData.data[0];
        console.log("userAuth", this.userAuth);
        if (!this.userAuth.isCanView) {
          // Swal.fire({
          //   icon: "error",
          //   title: "ไม่มีสิทธิ์เข้าใช้งาน",
          //   text: "ดึงข้อมูลไม่สำเร็จ กรุณาตรวจสอบ SERVER",
          // });
          this.$router.push("/404");
        }
      } else {
      }
    },
  },
};
</script>
